import { Component } from "react";

import Chart from "../imgs/chart.svg";

export default class GeneralInfo extends Component<{onStartTest: ()=>void, onGoBackClicked: ()=> void}>{
    render() {
        
        return <div className='general-info-wrapper-bg'>
            <div className='general-info-wrapper'>
            ნაშრომში გამოთქმული მოსაზრებები ეკუთვნის ავტორებს და შეიძლება არ გამოხატავდეს ევროპის საბჭოს ოფიციალურ პოლიტიკას. <br/><br/>კითხვარი მომზადდა ევროპის საბჭოს პროექტის „ქალთა მიმართ ძალადობის აღსაკვეთად ინტეგრირებული მიდგომის ხელშეწყობა და გენდერული თანასწორობის გაძლიერება საქართველოში“ მხარდაჭერით და ფორსეტთან პარტნიორობით, დათაფესტ თბილისის ფარგლებში. 
            <br/><br/>
            იდეის ავტორები, დიზაინი და ტექნიკური განხორციელება: <b>ეკა ტაბლიაშვილი, მიხეილ აკოფოვი და გიორგი დუნდუა</b>.<br/><br/> კითხვარი შეარჩია და შესაბამისი განმარტებები მოამზადა ევროპის საბჭოს კონსულტანტმა. კითხვარი აღებულია შემდეგი სტატიიდან: Sackett, L. A., & Saunders, D. G. (1999). The impact of dierent forms of psychological abuse on battered women. Violence and Victims, 14(1), 105–117. <a href="https://doi.org/10.1891/0886-6708.14.1.105" target="blank">https://doi.org/10.1891/0886-6708.14.1.105</a>) <br/>
            <br/> 2023 წელი
        <br/>
        <br/>
        
            <span className="primary-button" onClick={() => {
                         this.props.onStartTest();
                    }}>დაიწყე ქვიზი</span> 


            <span className="secondary-button" onClick={() => {
                         this.props.onGoBackClicked();
                    }}>უკან დაბრუნება</span> 
                
                
<hr/>
<span className="sub-title">შესავალი</span>
ძალადობრივი ურთიერთობის ნიშნები ყოველთვის არ არის ადვილი შესამჩნევი, ზოგჯერ პარტნიორი ურთიერთობაში არ იყენებს ფიზიკურ თავდასხმასა და შეურაცხყოფას, თუმცა მიმართავს ძალადობის ისეთ ფორმებს, რომელიც <b>“უჩინარი ძალადობის”</b> ფორმას იღებს. აღნიშნული კითხვარი მოგცემთ საშუალებას გასცეთ თითოეულ კითხვას პასუხი და გაიხსენოთ, ხომ არ გამოგიცდიათ მსგავსი რამ პარტნიორთან ურთიერთობაში. ეს არის <b>პირველი ნაბიჯი</b>, რომ <b>გამოავლინოთ</b> და <b>გააცხადოთ</b> თქვენზე განხორციელებული <b>ფსიქოლოგიური ძალადობის</b> გამოცდილება.
<br/>
<br/>

<span className="primary-button" onClick={() => {
                        this.props.onStartTest();
                    }}>დაიწყე ქვიზი</span> 
<hr/>
<span className="sub-title">გამოყენება:</span>
ონლაინ კითხვარი არ წარმოადგენს თქვენი გამოცდილების დიაგნოზსა და მტკიცებულებას, ეს არის ინსტრუმენტი, რომელიც <b>დაგეხმარებათ თვითანალიზში, გამოცდილების თვითშეფასებაში. დაგაფიქრებთ ოჯახურ დინამიკასა და პარტნიორის ქცევის სტილზე, მოგცემთ ისეთი ქცევების იდენტიფიცირების საშუალებას, რომელიც თქვენთვის შესაძლოა საზიანო ან საფრთხის შემცველი იყოს.</b><br/>
თუ აღმოაჩენთ, რომ კითხვები თქვენს გამოცდილებას ეხმიანება, იფიქრეთ ფსიქიკური ჯანმრთელობის სპეციალისტთან ან სოციალურ სერვისებთან დაკავშირებაზე. <br/>
<b>ფსიქოლოგიური ძალადობის მსხვერპლი ნებისმიერი ადამიანი შეიძლება გახდეს, განურჩევლად სქესის, ასაკის, ეროვნების თუ სექსუალური ორიენტაციისა. <br/>
გახსოვდეთ, რომ თქვენ მარტო არ ხართ და დახმარება ხელმისაწვდომია.</b>
<hr/>
<span className="highlihted">
აღნიშნეთ რამდენად ხშირად გამოგიცდიათ თქვენი პარტნიორისგან მსგავსი ქცევა და აირჩიეთ ჩამოთვლილი პასუხებიდან რომელიმე მათგანი.<br/>
თუ არ ხართ კონკრეტული სიხშირეში დარწმუნებული, გთხოვთ აირჩიოთ ყველაზე ახლო ვარიანტი.<br/>
თუ აღარ გყავთ პარტნიორი, უპასუხეთ ბოლო გამოცდილების მიხედვით.
</span>
            </div>

            <div className="chart-wrapper">
                <span className="chart-title">საქართველოში ოჯახური ძალადობის სახეების სტატისტიკა შემაკავებელი ორდერების მიხედვით 2015-2022 წლებში</span>
                <img src={Chart} className="chart"></img>
            </div>
        </div>
    }
}