import { QuestionModel } from "../models/QuestionModel";


export const questions: QuestionModel[] = [
    {
      text: "ბრაზდება ან წყინს თუ მის გარდა სხვასთან ყოფნა გინდათ?",
      bgImage:"./imgs/question_bg1.svg",
      bgColor:"#FDA979",
      questionColor:"#222433",
      options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "მოითხოვს ყველაფრის შესრულებას  კონკრეტული გზით?",
      bgImage:"./imgs/question_bg2.svg",
      bgColor:"#70D9D9",
      questionColor:"#222433",
       options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "არ გაძლევს უფლებას გქონდეს საკუთარი მეილი ან/და ისარგებლოთ სატელეფონო ზარებით?",
      bgImage:"./imgs/question_bg3.svg",
      bgColor:"#70D9D9",
      questionColor:"#222433",
       options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "ითხოვს ანგარიში ჩააბაროთ იმ პერიოდზე, რომელსაც მისგან მოშორებით ატარებთ?",
      bgImage:"./imgs/question_bg4.svg",
      bgColor:"#222433",
      questionColor:"#FFFFFF",
       options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "გაიგნორებთ როცა ლაპარაკს იწყებთ?",
      bgImage:"./imgs/question_bg5.svg",
      bgColor:"#70D9D9",
      questionColor:"#222433",
       options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "ამბობს რომ გიჟი ან სულელი ხართ?",
      bgImage:"./imgs/question_bg6.svg",
      bgColor:"#FDA979",
      questionColor:"#222433",
       options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "ეჭვიანობს მეგობრებზე, ოჯახზე ან შინაურ ცხოველებზე?",
      bgImage:"./imgs/question_bg7.svg",
      bgColor:"#FF7F36",
      questionColor:"#222433",
       options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "გაკრიტიკებთ როცა ტირით ან ემოციური მხარდაჭერა გჭირდებათ?",
      bgImage:"./imgs/question_bg8.svg",
      bgColor:"#222433",
      questionColor:"#FFFFFF",
       options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "გეუბნებათ, რომ უვარგისი ხართ?",
      bgImage:"./imgs/question_bg9.svg",
      bgColor:"#70D9D9",
      questionColor:"#222433",
       options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "ითხოვს თქვენი აქტივობების ანგარიშის ჩაბარებას?",
      bgImage:"./imgs/question_bg10.svg",
      bgColor:"#15C2C1",
      questionColor:"#222433",
       options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "აიგნორებს თქვენი დახმარების საჭიროებას, როცა ავად ან დაღლილი ხართ?",
      bgImage:"./imgs/question_bg11.svg",
      bgColor:"#FDA979",
      questionColor:"#222433",
       options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "გეუბნებათ, რომ სადილი კარგად ვერ მოამზადეთ და სახლი კარგად ვერ დაალაგეთ?",
      bgImage:"./imgs/question_bg12.svg",
      bgColor:"#FDA979",
      questionColor:"#222433",
       options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "მასხრად იგდებს იმ ღირებულებებს, რასაც ყველაზე მეტად აფასებთ საკუთარ თავში?",
      bgImage:"./imgs/question_bg13.svg",
      bgColor:"#70D9D9",
      questionColor:"#222433",
       options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "გაკრიტიკებთ სახლის ან სახლს გარეთ საქმიანობების გამო?",
      bgImage:"./imgs/question_bg14.svg",
      bgColor:"#70D9D9",
      questionColor:"#222433",
       options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "გამოწმებთ მთელი დღის განმავლობაში?",
      bgImage:"./imgs/question_bg15.svg",
      bgColor:"#15C2C1",
      questionColor:"#222433",
       options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "გაგრძნობინებთ, რომ ტელევიზორი, სხვა ადამიანები, ჟურნალები ან გაზეთები უფრო საინტერესოა ვიდრე თქვენ?",
      bgImage:"./imgs/question_bg16.svg",
      bgColor:"#FDA979",
      questionColor:"#222433",
       options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "გემუქრებათ რომ რამეს დაუშავებს თქვენს ცხოველებს, მეგობრებს, ან ნათესავებს, თუ არ გააკეთებთ იმას, რასაც გეუბნებათ?",
      bgImage:"./imgs/question_bg17.svg",
      bgColor:"#70D9D9",
      questionColor:"#222433",
       options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "გიწოდებთ ისეთ სახელებს, როგორიც არის „მეძავი“ ან „ბოზი“?",
      bgImage:"./imgs/question_bg18.svg",
      bgColor:"#222433",
      questionColor:"#FFFFFF",
       options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "გადანაშაულებთ ღალატში?",
      bgImage:"./imgs/question_bg19.svg",
      bgColor:"#70D9D9",
      questionColor:"#222433",
       options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "არ ერთვება იმ სექსუალურ აქტივობებში, რაც თქვენთვის სიამოვნების მომტანია? ",
      bgImage:"./imgs/question_bg20.svg",
      bgColor:"#FDA979",
      questionColor:"#222433",
       options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
    {
      text: "აუფასურებს თქვენს გეგმებს ან აკნინებს თქვენს წარმატებას?",
      bgImage:"./imgs/question_bg21.svg",
      bgColor:"#70D9D9",
      questionColor:"#222433",
      options: [
        { id: "0", text: "არასდროს", isCorrect: false },
        { id: "1", text: "უფრო იშვიათად, ვიდრე თვეში ერთხელ", isCorrect: false },
        { id: "2", text: "თვეში ერთხელ", isCorrect: false },
        { id: "3", text: "თვეში 2-3-ჯერ", isCorrect: true },
        { id: "4", text: "კვირაში ერთხელ", isCorrect: true },
        { id: "5", text: "კვირაში 2-3-ჯერ", isCorrect: true },
        { id: "6", text: "ყოველდღე", isCorrect: true },
      ],
    },
  ];